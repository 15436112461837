define([
    'lodash',
    'zepto',
    'warmupUtils/loggingUtils/logger/logger',
    'warmupUtils/loggingUtils/logger/performance',
    'warmupUtils/tpa/bi/events',
    'warmupUtils/tpa/services/tpaDataService',
    'experiment'
], function (
    _,
    $,
    logger,
    performance,
    events,
    tpaDataService,
    experiment
) {
    'use strict';

    const WIX_VOD_APP_DEF_ID = '14409595-f076-4753-8303-9a86f9f71469';
    const SOCIAL_BLOG_APP_DEF_ID = '14bcded7-0066-7c35-14d7-466cb3f09103';
    const FORUM_APP_DEF_ID = '14724f35-6794-cd1a-0244-25fd138f9242';
    const FORUM_WIDGET_ID = '1489040e-001f-4631-55a9-2c29b4417126';
    const WIX_STORES_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';
    const GALLERY_WIDGET_ID = '1380bba0-253e-a800-a235-88821cf3f8a4';
    const PRODUCT_WIDGET_WIDGET_ID = '13ec3e79-e668-cc0c-2d48-e99d53a213dd';
    const PRODUCT_PAGE_WIDGET_ID = '13a94f09-2766-3c40-4a32-8edb5acdd8bc';
    const GRID_GALLERY_WIDGET_ID = '13afb094-84f9-739f-44fd-78d036adb028';
    const CHECKOUT_PAGE_WIDGET_ID = '14fd5970-8072-c276-1246-058b79e70c1a';
    const WIX_BOOKINGS_APP_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';
    const SCHEDULER_WIDGET_ID = '13d27016-697f-b82f-7512-8e20854c09f6';
    const SERVICE_WIDGET_ID = '14756c3d-f10a-45fc-4df1-808f22aabe80';
    const PRO_GALLERY_APP_DEF_ID = '14271d6f-ba62-d045-549b-ab972ae1f70e';
    const PRO_GALLERY_WIDGET_ID = '142bb34d-3439-576a-7118-683e690a1e0d';
    const ART_STORE_APP_DEF_ID = '147ab90e-91c5-21b2-d6ca-444c28c8a23b';
    const WIX_EVENTS_APP_DEF_ID = '140603ad-af8d-84a5-2c80-a0f60cb47351';
    const GS_APP_DEF_ID = '1375baa8-8eca-5659-ce9d-455b2009250d';
    const GS_WIDGET_ID = '1375babd-6f2b-87ed-ff19-5778602c8b86';

    //wix restaurants
    const MENUS_APP_DEF_ID = '13c1402c-27f2-d4ab-7463-ee7c89e07578';

    const SUPPORTED_TPAS = {
        [WIX_VOD_APP_DEF_ID]: true,
        [SOCIAL_BLOG_APP_DEF_ID]: true,
        [FORUM_APP_DEF_ID]: {
            [FORUM_WIDGET_ID]: true
        },
        [WIX_STORES_APP_DEF_ID]: {
            [GALLERY_WIDGET_ID]: true,
            [PRODUCT_PAGE_WIDGET_ID]: true,
            [GRID_GALLERY_WIDGET_ID]: true,
            [PRODUCT_WIDGET_WIDGET_ID]: true,
            [CHECKOUT_PAGE_WIDGET_ID]: true
        },
        [WIX_BOOKINGS_APP_DEF_ID]: {
            [SCHEDULER_WIDGET_ID]: true,
            [SERVICE_WIDGET_ID]: true
        },
        [PRO_GALLERY_APP_DEF_ID]: {
            [PRO_GALLERY_WIDGET_ID]: true
        },
        [ART_STORE_APP_DEF_ID]: true,
        [WIX_EVENTS_APP_DEF_ID]: true,
        [MENUS_APP_DEF_ID]: true
    };

    const TPA_COMP_TYPES = {
        TPA_SECTION: 'wysiwyg.viewer.components.tpapps.TPASection',
        TPA_MULTI_SECTION: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
        TPA_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        TPA_GLUED_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
        TPA_WORKER: 'tpa.viewer.classes.TPAWorker'
    };

    function getComponentFromStructure(siteData, compId, pageId) {
        const pageData = siteData.getPageData(pageId) || _.get(siteData, ['pagesData', pageId]);
        const pageStructure = _.get(pageData, ['structure', siteData.getViewMode()]);
        return _.get(pageStructure, compId);
    }

    function getComponentFromCurrentAndMasterPage(siteData, compId) {
        const currentPageId = siteData.getCurrentUrlPageId();
        return getComponentFromStructure(siteData, compId, currentPageId) || getComponentFromStructure(siteData, compId, siteData.MASTER_PAGE_ID);
    }

    function isTPAComp(siteData, compId) {
        if (compId) {
            const comp = getComponentFromCurrentAndMasterPage(siteData, compId);
            if (comp) {
                return _.includes(TPA_COMP_TYPES, comp.componentType);
            }
        }
        return false;
    }

    function checkIsInSSR({tpasRenderedInSsr}, compId) {
        return (tpasRenderedInSsr && tpasRenderedInSsr[compId]) === true;
    }

    function reportPerformanceBiEvent(eventDescriptor, reportBiFunc, biData, compId, isInSSR, {isWixTPA, appDefinitionId} = {}, widgetId, params) {
        const time = biData.getTime();

        const eventParams = {
            compId,
            pageId: biData.getPageId(),
            pageNo: biData.getPageNumber(),
            ssr: isInSSR,
            loadingTime: time.loadingTime,
            totalLoadingTime: time.totalLoadingTime
        };

        if (isWixTPA) {
            eventParams.widgetId = widgetId;
            eventParams.appDefinitionId = appDefinitionId;
        } else if (!(params && params.widgetId && params.appDefinitionId)) {
            eventParams.externalWidgetId = widgetId;
            eventParams.externalAppDefinitionId = appDefinitionId;
        }

        _.merge(eventParams, params);
        reportBiFunc(eventDescriptor, eventParams);
    }

    function reportWarmupPerformanceBiEvent(eventDescriptor, siteData, compId, params) {
        let widgetId, appData;
        const comp = getComponentFromCurrentAndMasterPage(siteData, compId);
        if (comp) {
            const compData = siteData.getDataByQuery(comp.dataQuery);
            appData = siteData.rendererModel.clientSpecMap[compData && compData.applicationId];
            widgetId = compData.widgetId;
        }
        const reportBiFunc = _.partial(logger.reportBI, siteData);
        const isInSSR = checkIsInSSR(siteData, compId);
        reportPerformanceBiEvent(eventDescriptor, reportBiFunc, siteData.biData, compId, isInSSR, appData, widgetId, params);
        if (eventDescriptor.eventId === events.APP_LOADED_SUCCESSFULLY.eventId) {
            performance.finish(`Section ${compId}`, {category: 'sections', retainMarks: true});
        }
    }

    function isAboveTheFold(siteData, compId) {
        if (!window) {
            return true;
        }
        const $window = $(window);
        const windowBottom = $window.scrollTop() + $window.height();
        let compTop = _.get(siteData, ['measureMap', 'absoluteTop', compId]);
        if (!compTop) {
            const $comp = $(`#${compId}`);
            const offset = $comp.offset();
            if (offset) {
                compTop = offset.top;
            }
        }
        return compTop < windowBottom;
    }

    // eslint-disable-next-line complexity
    function isTpaSupportedInWarmup(siteData, compId) {
        if (!compId) {
            return false;
        }
        if (checkIsInSSR(siteData, compId)) {
            return true;
        }
        const comp = getComponentFromCurrentAndMasterPage(siteData, compId);
        if (comp) {
            const compData = siteData.getDataByQuery(comp.dataQuery);
            const appData = siteData.rendererModel.clientSpecMap[compData && compData.applicationId];
            if (appData) {
                const widgetId = compData.widgetId;
                if (isTpaSupported(appData, widgetId, experiment.isOpen, siteData) && (widgetId === GS_WIDGET_ID || isAboveTheFold(siteData, compId))) {
                    siteData.tpasRenderedInSsr[compId] = true;
                    return true;
                }
            }
        }
        return false;
    }

    // eslint-disable-next-line complexity
    function getSupportedTPAs(siteData, isExperimentOpenFunc) {
        const supportedTPAs = _.assign({}, SUPPORTED_TPAS);
        if (isExperimentOpenFunc('sv_ssrLoadGetSubscrbers', siteData)) {
            supportedTPAs[GS_APP_DEF_ID] = {
                [GS_WIDGET_ID]: true
            };
        }
        return supportedTPAs;
    }

    function isTpaSupported({appDefinitionId}, widgetId, isExperimentOpenFunc, siteData) {
        if (isExperimentOpenFunc('bv_stopRenderIframeWithSrc')) {
            return false;
        }
        const supportedTPAs = getSupportedTPAs(siteData, isExperimentOpenFunc);
        const supported = supportedTPAs[appDefinitionId];
        return supported && (!_.isObject(supported) || supported[widgetId]);
    }

    function getTemplateValuesFromUrl(url) {
        const TEMPLATE_REG_EXP = /\${(.*?)}/g;
        const templates = [];
        let match = TEMPLATE_REG_EXP.exec(url);
        while (match) {
            templates.push(match[1]);
            match = TEMPLATE_REG_EXP.exec(url);
        }
        return templates;
    }

    return {
        tpaDataService,
        events,
        isTPAComp,
        reportPerformanceBiEvent,
        reportWarmupPerformanceBiEvent,
        isTpaSupportedInWarmup,
        isTpaSupported,
        getComponentFromCurrentAndMasterPage,
        getTemplateValuesFromUrl
    };
});
