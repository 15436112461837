define([
    'warmupUtils/loggingUtils/bi/errors',
    'warmupUtils/loggingUtils/logger/fedopsLogger',
    'warmupUtils/loggingUtils/logger/logger',
    'warmupUtils/loggingUtils/logger/performance',
    'warmupUtils/loggingUtils/logger/newrelic',
    'warmupUtils/loggingUtils/logger/imagesBi',
    'warmupUtils/loggingUtils/logger/performanceMetrics'
], function (biErrors, fedopsLogger, logger, performance, newrelic, imagesBi, performanceMetrics) {
    'use strict';

    /**
     * @exports loggerUtils
     */
    const exports = {
        bi: {
            errors: biErrors
        },
        fedopsLogger,
        logger,
        performance,
        newrelic,
        imagesBi,
        performanceMetrics
    };
    return exports;
});
